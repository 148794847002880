<template>
  <v-card>
    <v-card-title>
      <span class="text-h6">Nhân viên</span>
      <v-spacer></v-spacer>

      <v-btn small color="warning" outlined class="mr-1" @click="showDialogChangePassword">
        {{ $t('labels.change_password') }}
      </v-btn>
      <v-btn small color="primary" outlined class="mr-1" @click="submitDialog">
        {{ $t('labels.save') }}
      </v-btn>
      <v-btn small color="error" outlined @click="closeDialog">
        {{ $t('labels.close') }}
      </v-btn>
    </v-card-title>
    <v-card-text class="pb-0">
      <v-container>
        <v-row>
          <v-col cols="12" sm="3">
            <div class="mb-1">
              <v-carousel v-model="imgCarousel" height="216px">
                <v-carousel-item v-for="img in imgTypes" :key="img">
                  <template v-slot:default>
                    <img style="max-width: 100%; height: auto; object-fit: cover; aspect-ratio: 1 / 1;" v-viewer
                      :src="detail[img] || require('@/assets/user.png')" alt="" />
                  </template>
                </v-carousel-item>
              </v-carousel>
            </div>
            <div class="text-center">
              <v-btn x-small color="warning" @click="$refs.inputUploadFile.click()">{{ $t('labels.change') }}</v-btn>
              <input type="file" ref="inputUploadFile" accept="image/*" @change="onInputFileChange" class="d-none">
            </div>
          </v-col>
          <v-col cols="12" sm="9">
            <v-row>
              <v-col cols="12" sm="6">
                <v-text-field label="Họ tên" dense outlined hide-details class="c-input-small"
                  v-model="detail.full_name"></v-text-field>
              </v-col>

              <v-col cols="12" sm="6">
                <v-text-field label="Tài khoản" dense outlined hide-details class="c-input-small"
                  v-model="detail.email"></v-text-field>
              </v-col>

              <v-col cols="12" sm="6">
                <v-autocomplete v-model="detail.id_device" :items="devices" label="Thiết bị" dense outlined hide-details
                  class="c-input-small"></v-autocomplete>
              </v-col>

              <v-col cols="12" sm="6">
                <v-autocomplete v-model="detail.id_poss" :items="poss" :label="$t('labels.pos')" dense outlined
                  hide-details class="c-input-small" multiple></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6">
                <v-autocomplete v-model="detail.id_department" :items="departments" :label="$t('labels.department')" dense
                  outlined hide-details class="c-input-small"></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6">
                <v-autocomplete v-model="detail.id_position" :items="positions" :label="$t('labels.position')" dense
                  outlined hide-details class="c-input-small"></v-autocomplete>
              </v-col>
            </v-row>
            <div class="mb-3">

            </div>
            <!--<div class="mb-3">
              <v-autocomplete v-model="detail.permissions" :items="permissions"
                              label="Phân quyền" dense multiple></v-autocomplete>
            </div>-->
          </v-col>
        </v-row>
      </v-container>
      <small v-if="errMsg" class="red--text">{{ errMsg }}</small>
    </v-card-text>

    <template v-if="checkPermission(['pos_admin_permission', 'pos_admin_permission_all'])">
      <v-card-title>
        <span class="text-h6">{{ $t('labels.set_permission') }}</span>
      </v-card-title>
      <v-card-text>
        <v-row no-gutters>
          <v-col cols="12" md="4" v-for="item in permissions" :key="`p_${item.value}`">
            <v-checkbox dense hide-details class="c-input-small" v-model="detail.permissions" :value="item.value"
              :label="item.text" multiple></v-checkbox>
          </v-col>
        </v-row>
      </v-card-text>
    </template>

    <v-dialog v-model="dialogChangePassword" persistent max-width="350px">
      <v-card>
        <v-card-title class="text-h5 mb-3">{{ $t('labels.change_password') }}</v-card-title>

        <v-card-text>
          <v-text-field v-model="password" class="c-input-small mb-3" :label="$t('labels.password')"
            :type="passwordShow ? 'text' : 'password'" :append-icon="passwordShow ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="passwordShow = !passwordShow" autocomplete="off" autofocus dense outlined
            hide-details></v-text-field>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="hideDialogChangePassword">
            {{ $t('labels.cancel') }}
          </v-btn>
          <v-btn color="green darken-1" text @click="resetPassword" :disabled="!password || password.length < 6">
            {{ $t('labels.confirm') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { httpClient } from "@/libs/http";
import moment from "moment/moment";
import { compress } from "image-conversion";

export default {
  name: "DialogDetail",
  props: {
    employee: {
      type: Object,
      default: () => { }
    },
    positions: {
      type: Array,
      default: () => []
    },
    departments: {
      type: Array,
      default: () => []
    },
  },
  data: () => ({
    detail: {},
    errMsg: null,
    poss: [],
    devices: [],
    password: null,
    passwordShow: false,
    dialogChangePassword: false,
    permissions: [],
    filePath: null,
    imgCarousel: 0,
    imgTypes: ['image_portrait', 'image_idcard_front', 'image_idcard_back']
  }),
  computed: {

  },
  methods: {
    closeDialog() {
      this.$emit('closeDialog')
    },
    refreshData() {
      this.$emit('refreshData')
    },
    async onInputFileChange(e) {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t('messages.loading'));
        return false
      }
      this.isLoading = true
      let files = e.target.files || e.dataTransfer.files
      if (files && files[0]) {
        const filePath = await this.uploadFile(files[0])
        if (filePath) {
          this.detail[this.imgTypes[this.imgCarousel]] = `${process.env.VUE_APP_FILE_CDN_URL}/${filePath}`
        }
        this.$refs.inputUploadFile.value = null
      }
      this.isLoading = false
    },
    async uploadFile(file) {
      const resBlob = await compress(file, {
        quality: 0.85,
        type: "image/jpeg",
      })
      const newFile = new File([resBlob], `${moment().unix()}_${this.generateRandomString(10)}.jpg`, { type: "image/jpeg" })
      let fd = new FormData();
      fd.append("file", newFile);

      const { data } = await httpClient.post(`/upload-image`, fd)
      return data
    },
    async submitDialog() {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t('messages.loading'));
        return false
      }
      this.isLoading = true
      try {
        await httpClient.post('/employee-update-info', this.detail)
        this.closeDialog()
        this.refreshData()
        this.isLoading = false
      } catch (e) {
        this.isLoading = false
        const errMsg = e.response && e.response.data && e.response.data.error && e.response.data.error.message || null
        this.$vToastify.error(errMsg);
      }
    },
    getDetailEmployee() {
      httpClient.post('/employee-detail', {
        id: this.employee.id,
      }).then(({ data }) => {
        const employee = { ...data.employee }
        employee.id_poss = (data.poss || []).map(i => (i.id))
        employee.id_device = ''
        employee.permissions = [...data.permissions].map(i => (i.item_name))
        this.devices = (data.devices || []).map(i => {
          const codeSplit = (i.unique_code || '').split('-')
          const code = codeSplit[0] || 'CODE'
          let text = `${code}... - ${this.formatDateTime(i.create_time)}`
          if (i.status === 1) {
            text += ` (active)`
            employee.id_device = i.id
          }
          return {
            value: i.id,
            text,
          }
        })
        this.detail = { ...employee }
      })
    },
    async getPoss() {
      if (!this.checkPermission(['supper_admin'])) {
        const { poss } = window.me
        this.poss = [...poss].map(p => ({
          value: p.id,
          text: p.code || ''
        }))
        return false
      }
      const { data } = await httpClient.post('/all-pos')
      this.poss = data.map(e => ({
        value: e.id,
        text: `${e.code || ''} ${!e.status ? '(inactive)' : ''}`
      }))
    },
    async getPermissions() {
      const { data } = await httpClient.post('/permission-list')
      this.permissions = [...data].map(e => ({
        value: e.name,
        text: e.description
      }))
    },
    showDialogChangePassword() {
      this.dialogChangePassword = true
    },
    hideDialogChangePassword() {
      this.dialogChangePassword = false
    },
    async resetPassword() {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t('messages.loading'));
        return false
      }
      this.isLoading = true
      try {
        await httpClient.post('/employee-update-password', {
          password: this.password,
          identity_id: this.detail.id_identity
        })
        this.hideDialogChangePassword()
        this.isLoading = false
      } catch (e) {
        this.isLoading = false
        const errMsg = e.response && e.response.data && e.response.data.error && e.response.data.error.message || null
        this.$vToastify.error(errMsg);
      }
    }
  },
  mounted() {
    this.getDetailEmployee()
    this.getPoss()
    this.getPermissions()
  }
}
</script>

<style scoped lang="scss">
.permission-col {
  -moz-column-count: 3;
  -webkit-column-count: 3;
  column-count: 3;
  position: relative;
}
</style>
